import "./index.scss";
import { Helmet } from "react-helmet-async";
import { useContext, useEffect, useState } from "react";
import Context from "../../Context";
const Metas = (props) => {
    const { resumo } = useContext(Context);

    return (
        <>
            <Helmet>
                {props &&
                    <>
                        {(resumo.configuracoes || props.titulo) && <title>{props.titulo ? props.titulo : resumo.configuracoes.app_title}</title>}
                        {props.descricao && <meta name="description" content={props.descricao}></meta>}
                        {props.imagem && <meta name="image" content={props.imagem}></meta>}
                        {props.keywords && <meta name="keywords" content={props.keywords}></meta>}
                        {props.titulo && <meta property="og:title" content={props.titulo}></meta>}
                        {props.descricao && <meta property="og:description" content={props.descricao}></meta>}
                        {props.imagem && <meta property="og:image" content={props.imagem}></meta>}
                        {props.imagem && <meta property="og:image:width" content={props.width ? props.width : 1200}></meta>}
                        {props.imagem && <meta property="og:image:height" content={props.height ? props.height : 630}></meta>}
                        {window && <meta property="og:url" content={props.href ? props.href : window.location.href}></meta>}
                        {resumo.configuracoes && <meta property="og:site_name" content={resumo.configuracoes.app_title}></meta>}
                        {props && <meta property="og:type" content={props.type ? props.type : "website"}></meta>}
                        {props && <meta name="twitter:card" content={props.card ? props.card : "summary"}></meta>}
                        {resumo.configuracoes && <meta name="twitter:site" content={resumo.configuracoes.twitter}></meta>}
                        {props.titulo && <meta name="twitter:title" content={props.titulo}></meta>}
                        {props.descricao && <meta name="twitter:description" content={props.descricao}></meta>}
                        {props.imagem && <meta name="twitter:image" content={props.imagem}></meta>}
                    </>
                }
            </Helmet>
            {resumo.configuracoes && <h1 className="mainTitle">{props.titulo ? props.titulo : resumo.configuracoes.app_title}</h1>}
        </>
    )
}

export default Metas;