import './App.css';
import { Suspense, lazy, useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import Context from './Context';
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import ScrollToTop from "./Components/ScrollToTop";
import SEOHelper from "./Components/SEOHelper";
import axios from 'axios';
import Cookies from './Components/Cookies';
import Metas from './Components/Metas';
import TagManager from 'react-gtm-module';

const Home = lazy(() => import('./Pages/Home'));
const Produtos = lazy(() => import('./Pages/Produtos'));
const Colecoes = lazy(() => import('./Pages/Colecoes'));
const Linhas = lazy(() => import('./Pages/Linhas'));
const Linha = lazy(() => import('./Pages/Linha'));
const Produto = lazy(() => import('./Pages/Produto'));
const Sobre = lazy(() => import('./Pages/Sobre'));
const ListaRepresentantes = lazy(() => import('./Pages/ListaRepresentantes'));
const ListaLojas = lazy(() => import('./Pages/ListaLojas'));
const Comprar = lazy(() => import('./Pages/Comprar'));
const Clientes = lazy(() => import('./Pages/Clientes'));
const Lojas = lazy(() => import('./Pages/Lojas'));
const Representantes = lazy(() => import('./Pages/Representantes'));
const Personalize = lazy(() => import('./Pages/Personalize'));
const Contato = lazy(() => import('./Pages/Contato'));
const Carrinho = lazy(() => import('./Pages/Carrinho'));
const Conta = lazy(() => import('./Pages/Conta'));
const Pedidos = lazy(() => import('./Pages/Pedidos'));
const Pedido = lazy(() => import('./Pages/Pedido'));
const Detalhes = lazy(() => import('./Pages/Detalhes'));
const Texto = lazy(() => import('./Pages/Texto'));

function App() {
  // localStorage.setItem("apiUrl", "https://regina.dev.fw2propaganda.com.br/sistema/api/");
  // localStorage.setItem("apiUrl", "https://ceramicaregina.com.br/sistema/api/");
  // localStorage.setItem("apiUrl", "http://localhost:8080/api/");
  localStorage.setItem("apiUrl", "/sistema/api/");
  const [resumo, setResumo] = useState({});
  const [modal, setModal] = useState(false);
  const [isRep, setIsRep] = useState(false);
  const [activePage, setActivePage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [cart, setCart] = useState([]);
  const [metas, setMetas] = useState({
    titulo: "",
    descricao: "",
    imagem: "",
    keywords: "",
    href: "",
    type: "website",
    card: "summary"
  });
  const value = { resumo, setResumo, modal, setModal, activePage, setActivePage, menuOpen, setMenuOpen, cart, setCart, metas, setMetas, isRep, setIsRep };

  useEffect(() => {
    axios.get(localStorage.getItem("apiUrl") + "configuracoes").then(response => {
      setResumo(response.data.success);
      if (response.data.success.configuracoes.tag_manager) {
        if (response.data.success.configuracoes.tag_manager.length > 5) {
          const tagManagerArgs = {
            gtmId: response.data.success.configuracoes.tag_manager
          }
          let checkCookies = setInterval(() => {
            let accept = localStorage.getItem("fw2AcceptCookiesDesnecessarios");
            if (accept) {
              TagManager.initialize(tagManagerArgs)
              clearInterval(checkCookies);
            }
          }, 3000);
        }
      }
    });

    let carrinho = JSON.parse(localStorage.getItem("carrinho"));
    if (!carrinho) {
      carrinho = []
    }
    setCart(carrinho);

    let id = localStorage.getItem("id");
    let codigo = localStorage.getItem("codigo");
    if (id && codigo) {
      axios.get(localStorage.getItem("apiUrl") + "check?id=" + id + "&codigo=" + codigo).then(response => {
        if (response.data.status !== 200) {
          localStorage.removeItem("id");
          localStorage.removeItem("codigo");
        } else {
          if (response.data.success.is_rep === 1) {
            setIsRep(true);
            localStorage.setItem("is_rep", 1);
          }
        }
      });
    }

  }, []);

  return (
    <div className="App">
      <HelmetProvider>
        <Context.Provider value={value}>
          <div id="modal" className={modal ? "active" : ""}>
            {modal && <div className="content">
              <h3>{modal.titulo}</h3>
              <p>{modal.texto}</p>
              <button type="button" onClick={() => { if (modal.onExit) { modal.onExit() } setModal(false) }}>{modal.botao}</button>
            </div>}
          </div>
          <Metas {...metas} />
          {resumo.textos &&
            <Router>
              <Header />
              <SEOHelper />
              <ScrollToTop setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
              <Suspense fallback={<div className="suspense"></div>}>
                <Switch>
                  <Route exact path="/">
                    <Home />
                  </Route>
                  <Route exact path="/empresa">
                    <Sobre />
                  </Route>
                  <Route path="/produtos" exact>
                    <Colecoes />
                  </Route>
                  <Route path="/categoria/:slug" exact>
                    <Colecoes />
                  </Route>
                  <Route path="/colecao/:slug" exact>
                    <Linhas />
                  </Route>
                  <Route path="/colecao/:colecao/linha/:slug" exact>
                    <Linha />
                  </Route>
                  <Route path="/caixa" exact>
                    <Produtos />
                  </Route>
                  <Route path="/pesquisa/:pesquisa" exact>
                    <Produtos />
                  </Route>
                  <Route exact path="/produto/:slug">
                    <Produto />
                  </Route>
                  <Route exact path="/carrinho">
                    <Carrinho />
                  </Route>
                  <Route exact path="/como-comprar">
                    <Comprar />
                  </Route>
                  <Route exact path="/lojas">
                    <Lojas />
                  </Route>
                  <Route exact path="/lojas/:cidade">
                    <ListaLojas />
                  </Route>
                  <Route exact path="/representantes">
                    <Representantes />
                  </Route>
                  <Route exact path="/representantes/:cidade">
                    <ListaRepresentantes />
                  </Route>
                  <Route exact path="/personalize-seu-produto">
                    <Personalize />
                  </Route>
                  <Route exact path="/fale-conosco">
                    <Contato />
                  </Route>
                  <Route exact path="/minha-conta">
                    <Conta />
                  </Route>
                  <Route path="/minha-conta/:id/:codigo" exact>
                    <Conta />
                  </Route>
                  <Route exact path="/pedidos">
                    <Pedidos />
                  </Route>
                  <Route exact path="/pedido/:id">
                    <Pedido />
                  </Route>
                  <Route exact path="/obrigado/:id">
                    <Pedido />
                  </Route>
                  <Route exact path="/detalhes">
                    <Detalhes />
                  </Route>
                  <Route exact path="/clientes">
                    <Clientes />
                  </Route>
                  {resumo.textos &&
                    <Route exact path="/termos-de-uso">
                      <Texto titulo={"Termos de uso"} texto={resumo.textos.termos_de_uso} />
                    </Route>
                  }
                  {resumo.textos &&
                    <Route exact path="/politica-de-privacidade">
                      <Texto titulo={"Política de privacidade"} texto={resumo.textos.politica_de_privacidade} />
                    </Route>
                  }
                </Switch>
              </Suspense>
              <Cookies />
              <Footer />
            </Router>
          }
        </Context.Provider>
      </HelmetProvider>
    </div>
  );
}

export default App;
