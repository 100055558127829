import "./index.scss";
import { Link } from "react-router-dom";
import Facebook from "../../Assets/images/icons/facebook.svg";
import Instagram from "../../Assets/images/icons/instagram.svg";
import Whatsapp from "../../Assets/images/icons/whatsapp.svg";
import { useContext } from "react";
import Context from "../../Context";

const Footer = () => {
    const { resumo } = useContext(Context);
    return (
        <div id="footer">
            <div className="container">
                <div className="row">
                    <div className="col-3">
                        <Link to="/" className="logo" title="Cerâmica Regina"><img alt="Logo" src={resumo.imagens.logo} /></Link>
                    </div>
                    <div className="col-9">
                        <div className="menu">
                            <Link to="/personalize-seu-produto" title="Personalize sua caneca!">Personalize sua caneca!</Link>
                            <Link to="/fale-conosco" title="Entre em contato">Entre em contato</Link>
                            <div className="socials">
                                <a href={resumo.configuracoes.facebook} target="_blank" rel="noopener noreferrer" title="Facebook"><img src={Facebook} alt="Facebook" /></a>
                                <a href={resumo.configuracoes.instagram} target="_blank" rel="noopener noreferrer" title="Instagram"><img src={Instagram} alt="Instagram" /></a>
                                <a href={`https://wa.me/55${resumo.configuracoes.whatsapp.replace(/[^0-9-]/g, "").replace(/(?!^)-/g, "")}`} target="_blank" rel="noopener noreferrer" title="Whatsapp"><img src={Whatsapp} alt="Whatsapp" /></a>
                            </div>
                            <Link to="/" className="button" title="Baixe nosso catálogo">Baixe nosso catálogo</Link>
                        </div>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="endereco">
                    <p>
                        Av. Dr. Hermidio Salzano, 360  - CEP: 13660-000 - Porto Ferreira - SP
                    </p>
                    <a title="Desenvolvido por Gustavo Barbosa" href="https://fw2propaganda.com.br/" target="_blank" rel="noopener noreferrer">Desenvolvido por <b>FW2 Propaganda ❤</b></a>
                </div>
            </div>
        </div>
    )
}

export default Footer;