import "./index.scss";
import { Link, useHistory } from "react-router-dom";
import Pesquisa from "../../Assets/images/icons/pesquisa.svg";
import Carrinho from "../../Assets/images/icons/carrinho.svg";
import Menu from "../../Assets/images/icons/menu.svg";
import Close from "../../Assets/images/icons/close.svg";
import { useContext, useState } from "react";
import Context from "../../Context";
import { useEffect } from "react";


const Header = () => {
    const { menuOpen, setMenuOpen, resumo } = useContext(Context);
    const [pesquisa, setPesquisa] = useState(false);
    const history = useHistory();
    const [update, setUpdate] = useState(false);
    const handlePesquisa = (e) => {
        e.preventDefault();
        let formdata = new FormData(e.target);
        history.push(`/pesquisa/${formdata.get("pesquisa")}`);
        e.target.reset();
        setPesquisa(false);
    }

    useEffect(() => {
        setInterval(() => setUpdate(!update), 2000);
    }, []);

    return (
        <div id="header">
            <div className="container menu-responsive">
                <div>
                    <Link to="/" className="logo"><img alt="Logo" src={resumo.imagens.logo.replace("/large/", "/medium/")} /></Link>
                </div>
                <div>
                    <img alt="Menu" src={Menu} onClick={() => setMenuOpen(!menuOpen)} />
                </div>
            </div>
            <div className={`container menu ${menuOpen ? "active" : ""}`}>
                <div>
                    <img className="closemenu" alt="Menu" src={Close} onClick={() => setMenuOpen(!menuOpen)} />
                    <Link to="/" title="Home"> Home </Link>
                    <Link to="/empresa" title="Sobre nós"> Sobre nós</Link>
                    <Link to="/produtos" title="Produtos"> Produtos </Link>
                    <Link to="/como-comprar" title="Como comprar"> Como comprar </Link>
                </div>
                <div>
                    <Link to="/" className="logo"><img alt="Logo" src={resumo.imagens.logo.replace("/large/", "/medium/")} title="Cerâmica Regina" /></Link>
                </div>
                <div>
                    <img onClick={() => setPesquisa(true)} alt="Pesquisa" src={Pesquisa} title="Pesquisar" />
                    <Link className="carrinhoLink" to="/carrinho" title="Carrinho" ><img alt="Carrinho" src={Carrinho} />{(update || !update) && localStorage.getItem("carrinho") && <span>{JSON.parse(localStorage.getItem("carrinho")).length}</span>}</Link>
                    <Link to="/minha-conta" className="button" title="Minha conta"> Minha Conta </Link>
                </div>
            </div>
            <div className={`pesquisa ${pesquisa ? "active" : ""}`}>
                <div className="form">
                    <form onSubmit={handlePesquisa}>
                        <div className="inputs">
                            <label htmlFor="pesquisa">Pesquisar</label>
                            <input type="text" name="pesquisa" id="pesquisa" required />
                            <button type="submit" title="pesquisar"><img src={Pesquisa} alt="pesquisa"  title="Pesquisar" /></button>
                            <span onClick={() => setPesquisa(false)}>Voltar ao site</span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
};

export default Header;



